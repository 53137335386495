
  import { defineComponent, inject, onMounted, ref} from "vue";
  import { useRouter, useRoute } from "vue-router";
  import Modal from "@/components/UI/Modal";
  import Toast from "@/components/UI/Toast";
  import axios from "@/api/axios";
  import $ from "jquery";
  import qs from "qs";
  export default defineComponent({
    name: "yearVote",
    components: {
    },
    setup() {
      const personalName = ref()
      const staffListA = ref()
      const staffListB = ref()
      const votingLimitA = ref()
      const votingLimitB = ref()
      const limitData = ref(true)
      const router = useRouter();
      const route = useRoute();
      //  展示用户信息
      const userInfo = inject("userInfo") as any;


      onMounted(() => {
        const token = localStorage.getItem("token");
        if (!token) {
          router.push({
            name: "Login",
            query: {
              redirect: router.currentRoute.value.fullPath,
            },
          });
          return false;
        }
        $('.home-wapper').css('padding-top', '0')
        axios
                .post("/M/Staff/findListStaffByDeptNumLeader2024")
                .then((res) => {
                  const data = res.data
                  if (data.success) {
                    data.obj.staffList101.forEach((item: any) => {
                      item.disable = false
                      item.forbid = false
                    });
                    staffListA.value = data.obj.staffList101,
                    staffListB.value = data.obj.staffList102,
                    votingLimitA.value = 6,
                    votingLimitB.value = 12,
                    personalName.value = data.obj.currentStaff.staffName
                  } else {
                    limitData.value = false
                    // Toast({
                    //   type: "error",
                    //   title: res.data.msg,
                    // });
                  }
                })
                .catch((err) => {
                  console.log(err)
                });

      })

      function handleChangeA(item: any, index: any) {
        let sum = 0
        staffListA.value.forEach((item: any) => {
          if (item.disable==true) {
            sum += 1
          }
        })
        if (sum>=votingLimitA.value) {
          staffListA.value.forEach((item: any) => {
            if (item.disable!=true) {
              item.forbid = true
            }
          })
        } else {
          staffListA.value.forEach((item: any) => {
            item.forbid = false
          })
        }
      }

      function handleChangeB(item: any, index: any) {
        let sum = 0
        staffListB.value.forEach((item: any) => {
          if (item.disable==true) {
            sum += 1
          }
        })
        if (sum>=votingLimitB.value) {
          staffListB.value.forEach((item: any) => {
            if (item.disable!=true) {
              item.forbid = true
            }
          })
        } else {
          staffListB.value.forEach((item: any) => {
            item.forbid = false
          })
        }
      }

      function viewStatic() {
        const token = localStorage.getItem("token");
        if (!token) {
          Modal({
            title: "温馨提示",
            content: "登录后才可以进行投票！",
            onClose: () => {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            },
          });
          return false;
        } else {
          router.push({
            name: "leadVoteView"
          });
        }
      }

      function save() {
        const staffData: any = []
        staffListA.value.forEach((item: any) => {
          if(item.disable == true) {
            staffData.push(item.id)
          }
        })

        staffListB.value.forEach((item: any) => {
          if(item.disable == true) {
            staffData.push(item.id)
          }
        })

        if (staffData.length==0) {
          Toast({
            type: "error",
            title: '请选择您要投票的人！',
          });
          return false
        }
        const data = qs.stringify({
          beVotedIds: staffData.join(',')
        });
        const token = localStorage.getItem("token");
        if (!token) {
          Modal({
            title: "温馨提示",
            content: "登录后才可以进行投票！",
            onClose: () => {
              router.push({
                name: "Login",
                query: {
                  redirect: router.currentRoute.value.fullPath,
                },
              });
            },
          });
          return false;
        } else {
          axios
                  .post("/M/Staff/doVotingLeader2024", data, {
                    params: {
                      validate: true,
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      Toast({
                        type: "success",
                        title: res.data.msg,
                        onClose: () => {
                          router.push({
                            name: "leadVoteView"
                          });
                        },
                      });

                    } else {
                      Toast({
                        type: "error",
                        title: res.data.msg,
                      });
                    }
                  })
                  .catch((err) => {
                    console.log(err)
                  });
        }
      }

      const clearUserInfo = inject("CLEAR_USERINFO") as () => void;
      function outLogin() {
        localStorage.removeItem("token");
        clearUserInfo();
        router.push({
          name: "Login",
          query: {
            redirect: router.currentRoute.value.fullPath,
          },
        });
      }

      return {
        userInfo,
        handleChangeA,
        handleChangeB,
        save,
        viewStatic,
        staffListA,
        staffListB,
        votingLimitA,
        votingLimitB,
        limitData,
        outLogin,
        personalName
      }
    },
  });
